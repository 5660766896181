import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { MutationHookOptions, useMutation } from '@apollo/client';
import Cookies from 'js-cookie';
import { signOut, useSession } from 'next-auth/react';
import { PermissionKey, getTokenExpiry, AccountType } from '@srnade/component-ui';

import client from 'clients/apollo';
import { config } from 'config/cookie.config';
import { FIND_PROFILE, GET_UPDATED_TOKENS, HAS_PERMISSIONS } from 'services';
import { useCookie, useLocalStorage } from '@srnade/web/hooks';
import {
    AccountFragment,
    ClientTokensResponse,
    FindProfileQuery,
    FindProfileQueryVariables,
    GetUpdatedTokensMutation,
    GetUpdatedTokensMutationVariables,
    HasPermissionsQuery,
    HasPermissionsQueryVariables,
    PermissionKey as GeneratedPermissionKey,
} from '@srnade/web/__generated__/graphql';

// =================================
// Types, Interfaces, Enums
// =================================

export enum CookieKey {
    Token = 'token',
    refreshToken = 'refresh_token',
    Account = 'account',
    RedirectTo = 'redirect_to',
    CompleteProfileRedirectTo = 'complete_profile_redirect_to',
}

export enum SessionKey {
    RedirectTo = 'redirect_to',
}

export interface AuthState {
    authenticating: boolean;
    authenticated: boolean;
    user: null | FindProfileQuery['findProfile'];
    accountId: null | string;
    hasPermission: (accountId: string, permissionKey: PermissionKey) => Promise<boolean>;
    errors: object;
    logout: (redirect?: boolean) => Promise<void>;
    tokenCookie?: string | null;
    activeAccount?: null | AccountFragment;
    getUserProfile?: () => Promise<void>;
    isArtist: boolean;
}

interface AuthContextProvider {
    initialState?: Partial<AuthState>;
    children: ReactNode;
}

// =================================
// Constants
// =================================

// Initial state
const initialState: AuthState = {
    authenticating: true,
    authenticated: false,
    user: null,
    accountId: null,
    hasPermission: () => new Promise(() => false),
    errors: { login: null },
    logout: () => new Promise(() => undefined),
    activeAccount: null,
    isArtist: false,
};

// Auth context
export const AuthContext = createContext<AuthState>(initialState);

// =================================
// Static Methods
// =================================

/**
 * Context hook (useAuth)
 */
export function useAuth(): AuthState {
    return useContext<AuthState>(AuthContext);
}

/**
 * Fetches the user profile from the server
 * @returns user
 */
export async function getHotProfile(): Promise<FindProfileQuery['findProfile'] | null> {
    const { data } = await client.query<FindProfileQuery, FindProfileQueryVariables>({
        query: FIND_PROFILE,
        fetchPolicy: 'no-cache',
    });

    const user = data.findProfile;
    return user;
}

export function useGetUpdatedTokensMutation(
    options?: MutationHookOptions<GetUpdatedTokensMutation, GetUpdatedTokensMutationVariables>,
) {
    return useMutation<GetUpdatedTokensMutation, GetUpdatedTokensMutationVariables>(GET_UPDATED_TOKENS, options);
}

// =================================
// Auth Provider
// =================================

export const AuthProvider = ({ children, initialState }: AuthContextProvider): JSX.Element => {
    const [user, setUser] = useState<null | FindProfileQuery['findProfile']>(initialState?.user ?? null);
    // To prevent unexpected SSR hydration issue, we are storing the localStorage value in a separate state.
    // More info: https://blog.jannikwempe.com/react-pre-rendering-and-potential-hydration-issue
    const [, setCachedUser] = useLocalStorage<FindProfileQuery['findProfile'] | null>('user', null);
    const [authenticating, setAuthenticating] = useState<boolean>(initialState?.authenticating ?? true);
    const [accountId, setAccountCookie] = useCookie(CookieKey.Account, null);
    const [tokenCookie, setTokenCookie] = useCookie(CookieKey.Token, null);
    const [activeAccount, setActiveAccount] = useState<null | AccountFragment>(initialState?.activeAccount ?? null);
    const [isArtist, setIsArtist] = useState<boolean>(initialState?.isArtist ?? false);
    const { status: sessionStatus, data: sessionData } = useSession();
    // TODO enable once splitio issue is fixed (MARK-641)
    // const { pushUserEvent } = useGtmEvents(GtmUserEvent.LogoutSuccess);

    /**
     * Clears the user profile and tokens from the in-memory, localStorage and cache;
     */
    const clearUserStates = useCallback(async () => {
        setUser(null);
        setTokenCookie(null);
        setAccountCookie(null);
        setCachedUser(null);
        setActiveAccount(null);
        // clear cookies
        Cookies.remove(CookieKey.Account);
        Cookies.remove(CookieKey.Token);
        Cookies.remove(CookieKey.RedirectTo);
        // clear store
        await client.clearStore();
    }, [setAccountCookie, setCachedUser, setTokenCookie, setUser, setActiveAccount]);

    /**
     * Get the user profile
     */
    const getUserProfile = useCallback(async () => {
        try {
            const user = await getHotProfile();
            if (user !== null && user.accounts.length > 0) {
                const accountId = user.accounts[0].id;
                setUser(user);
                setCachedUser(user); // save the user profile in  the localStorage for future access
                setActiveAccount(user.accounts[0]);
                setAccountCookie(accountId, { expires: config.tokenExpiry });
            }
        } finally {
            setAuthenticating(false);
        }
    }, [setUser, setActiveAccount, setAccountCookie, setCachedUser, setAuthenticating]);

    /**
     * Set tokens and retrieve user profile
     */
    const setTokens = useCallback(
        async (tokens: Pick<ClientTokensResponse, 'accessToken'>) => {
            const { accessToken } = tokens;

            const expires = new Date(getTokenExpiry(accessToken)); // store the same expiration time as the token!
            //  Set the custom token to a cookie so the apollo client can send it as an authorization header in any subsequent calls
            // @todo: use `httpOnly: true` to prevent the token from being read by client-side JS. We cannot do it now as we are manually passing the token in the header via Apollo client.
            setTokenCookie(accessToken, {
                expires,
                SameSite: 'sameSite',
            });

            await getUserProfile();
        },
        [getUserProfile, setTokenCookie],
    );

    // Set authenticating flag based on the session status
    // This use effect utilises next-auth's session to determine the user's authentication status
    useEffect(() => {
        if (sessionStatus === 'unauthenticated') {
            setAuthenticating(false);
            void clearUserStates();
            return;
        }

        if (sessionStatus === 'authenticated' && sessionData?.accessToken) {
            void (async () => {
                await setTokens({
                    accessToken: sessionData.accessToken as string,
                });
                setAuthenticating(false);
            })();
        }
    }, [sessionData?.accessToken, sessionStatus, setTokens, setAuthenticating, clearUserStates]);

    /**
     * Does the authenticated user have the required role?
     */
    const hasPermission = useCallback(
        async (accountId: string, permissionKey: PermissionKey): Promise<boolean> => {
            if (!user) {
                throw new Error(`Invalid attempt to use \`hasPermission\` when user doesn't exist`);
            }

            const generatedPermissionKey = permissionKey as unknown as GeneratedPermissionKey;

            // @todo determine what role the user has on the account
            try {
                const { data } = await client.query<HasPermissionsQuery, HasPermissionsQueryVariables>({
                    query: HAS_PERMISSIONS,
                    variables: { keys: generatedPermissionKey },
                    fetchPolicy: 'no-cache',
                });
                return data.hasPermissions;
            } catch {}

            return false;
        },
        [user],
    );

    /**
     * Log out the user
     */
    const logout = useCallback(async (redirect?: boolean) => {
        const shouldRedirect = redirect === true || redirect === undefined;

        if (shouldRedirect) {
            await signOut({ callbackUrl: '/' });
        } else {
            await signOut({ redirect: false });
        }
    }, []);

    // Update the user profile when the user logs in/out from another tab
    const onUserStorageUpdate = useCallback(
        async (e: StorageEvent) => {
            if (e.key === 'user') {
                await getUserProfile();
            }
        },
        [getUserProfile],
    );

    // Listen to storage events to update the user profile
    useEffect(() => {
        window.addEventListener('storage', (event: StorageEvent) => {
            void onUserStorageUpdate(event);
        });
        return () => {
            window.removeEventListener('storage', (event: StorageEvent) => {
                void onUserStorageUpdate(event);
            });
        };
    }, [onUserStorageUpdate]);

    useEffect(() => {
        if (activeAccount && AccountType[activeAccount.type] === AccountType.Artist) {
            setIsArtist(true);
            return;
        }

        setIsArtist(false);
    }, [activeAccount]);

    const value: AuthState = {
        authenticating,
        authenticated: !!user,
        user,
        accountId,
        hasPermission,
        errors: {},
        logout,
        tokenCookie,
        activeAccount,
        getUserProfile,
        isArtist,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
