import { ApolloClient, InMemoryCache, from, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import Cookies from 'js-cookie';
import { sha256 } from 'crypto-hash';
import { v4 as uuidv4 } from 'uuid';

import { Product, Account, FirstOwner, Connection } from '../types';

export interface BaseEntity {
    id: string;
    created: Date;
    updated: Date;
    deactivated?: Date;
    isActive?: boolean;
    deleted?: Date;
    isDeleted?: boolean;
}

import { CookieKey } from '@srnade/web/auth';
import { hygraphClientName } from '@srnade/web/types';
import { ProductFormat } from '@srnade/web/__generated__/graphql';
import { getSession } from 'next-auth/react';

export * from '@apollo/client';

// @note using this middleware currently prevents `onCompleted` and `onError` event
// handlers from triggering on specific queries or mutations
/**
 * Error middleware
 */
const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) graphQLErrors.map(({ message }) => console.log(message));
});

/**
 * Upload middleware
 */
const uploadLink = createUploadLink({
    uri: `${process.env.NEXT_PUBLIC_SERVER_URL}/graphql`,
    // Added this in because when developing locally keep-alive cannot be used for the SSR Client
    // Makes the NextJS Node server crash
    headers:
        typeof window !== 'undefined'
            ? {
                  'keep-alive': 'true',
              }
            : {},
});

// Note: we use a proxy to the root url so we don't need to use `httpLink`
// const httpLink = createHttpLink({
//     uri: '/graphql',
// });

/**
 * Auth middleware
 */
const authLink = setContext(async (_, previousContext) => {
    const { headers } = previousContext;

    /**
     * Get the token and account cookies if we are running in the
     * browser. If not they will be provided manually when using the
     * client in getServerSideProps
     */
    let token = Cookies.get(CookieKey.Token);
    const account = Cookies.get(CookieKey.Account);

    // If token cookie is not found, then try to fetch it from the session
    if (!token) {
        const session = await getSession();

        if (session) {
            token = session.accessToken;
        }
    }

    return {
        ...previousContext,
        // Set the headers
        headers: {
            ...headers,
            'x-correlation-id': uuidv4(),
            /**
             * Only add the `account-id` header, if `account` is present in the browser cookie.
             * Otherwise it can be provided explicitly on the query.
             */
            ...(account ? { 'account-id': account } : {}),
            /**
             * Only add the token as an authorization header if the
             * token is present in the browser cookie.
             * Otherwise allow the client to provide the token explicitly on the query.
             */
            ...(token ? { authorization: `Bearer ${token}` } : {}),
        },
    };
});

type Ref = { __ref: string };

/**
 * Configure the Apollo client cache. We create policies to ensure Apollo knows when/how to merge
 * data on subsequent requests, predominantly for cursor (relay) based pagination. If we use the
 * same query across multiple components we have to supply `keyArgs` to the `relayStylePagination`
 * method so it knows to store the response in different stores.
 *
 * @see https://www.apollographql.com/docs/react/pagination/key-args/
 * @note A type policy is required for all cursor (relay) based pagination
 */
const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                productFormats: relayStylePagination<ProductFormat & Ref>(),
                findProducts: relayStylePagination<Product & Ref>(['type', 'tags', 'username']),
                findAccounts: relayStylePagination<Account & Ref>(['type', 'tags', 'status']),
                findFirstOwners: relayStylePagination<FirstOwner & Ref>(['type', 'productSlug']),
            },
        },
    },
});

const persistedQueryLink = createPersistedQueryLink({ sha256, useGETForHashedQueries: true });

const hygraphEndpoint = createUploadLink({
    uri: process.env.NEXT_PUBLIC_GRAPHCMS_URL,
    headers: {
        authorization: `Bearer ${process.env.NEXT_PUBLIC_GRAPHCMS_AUTH_TOKEN}`,
    },
});

/**
 * Create an instance of the Apollo client
 */
const client = new ApolloClient({
    link: ApolloLink.split(
        (operation) => operation.getContext().clientName === hygraphClientName,
        from([hygraphEndpoint as any]), // use this link if the clientName context is set to hygraph
        from([authLink, errorLink, persistedQueryLink, uploadLink as any]), // otherwise use this link
    ),
    cache,
    ssrMode: typeof window === 'undefined',
});

/**
 * Helper method to determine if relay style pagination has a next page
 * @param data Relay response
 * @returns `true` if next page exists
 */
export const hasNextPage = (data?: Connection<any>): boolean => {
    return data ? data.pageInfo.hasNextPage : false;
};

/**
 * Helper method to get the cursor for the last node in relay style pagination
 * @param data Relay response
 * @returns Cursor
 */
export const getAfter = (data): string | null => {
    return data.edges && data.edges.length > 0 ? data.edges[data.edges.length - 1].cursor : null;
};

export default client;
